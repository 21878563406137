import { isBrowser } from 'ws-scripts/modules/environment';

export const getCompareLink = (compareVehicles) => {
	let compareBaseUrl = '';
	if (isBrowser && window.DDC?.InvData?.COMPARE_PAGE) {
		compareBaseUrl = window.DDC.InvData.COMPARE_PAGE;
	}
	return compareBaseUrl
		? compareBaseUrl.concat(
				`?itemIds=${encodeURIComponent(compareVehicles.join())}`
		  )
		: '';
};

/**
 * helper that lets you know whether or not to show the compare functionality
 * @param viewportWidth
 * @param enableCompare Preference
 * @param deviceType
 * @returns {boolean}
 */
export const getShouldShowCompare = (enableCompare) => {
	// Compare functionality is enabled now on both desktop and mobile
	return enableCompare === 'true';
};
