import widgetInfo from './widgetInfo';
import layout from './layout';
import compareVehicles from './compare';
import modalSettings from './modalSettings';
import offSiteData from './offSiteData';
import userProfile from './userProfile';
import ctaCardItemlist from './ctaCardItemlist';

const root = {
	widgetInfo,
	layout,
	compareVehicles,
	modalSettings,
	offSiteData,
	userProfile,
	ctaCardItemlist
};

export default root;
