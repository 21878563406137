import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { usePrefs } from 'wsm-common-data';
import { setClassNames } from 'ddc-classnames-js';

import CalloutItem from './CalloutItem';
import {
	getTargetLocations,
	getVehicleLocationName
} from '../../utilities/getTargetLocations';
import { selectIsTabbedLayout } from '../../features/layoutSlice';

const Callout = ({ callouts, vehicle }) => {
	const isTabbedLayout = useSelector((state) => selectIsTabbedLayout(state));
	const { useSimplifiedListing } = useSelector((state) => state.layout);

	// This section allows us to use a preference to determine which vehicles should get the regular
	// WIAPI insert locations and which ones should have 'custom' locations added instead.
	const { restrictInsertLocationsForVehicles } = usePrefs();

	const targetLocations = getTargetLocations(
		vehicle,
		restrictInsertLocationsForVehicles
	);

	const vehicleBadgeLocation = getVehicleLocationName(
		'vehicle-badge',
		targetLocations,
		restrictInsertLocationsForVehicles
	);
	const vehicleMediaLocation = getVehicleLocationName(
		'vehicle-media',
		targetLocations,
		restrictInsertLocationsForVehicles
	);

	const calloutItems = [];
	if (targetLocations.size === 0 || !targetLocations.has('vehicle-ctas')) {
		calloutItems.push(
			...callouts.map((callout, index) => (
				// eslint-disable-next-line react/no-array-index-key
				<CalloutItem key={index} index={index} {...callout} />
			))
		);
	}

	return (
		<div
			className={setClassNames([
				'badge-callout',
				calloutItems.length === 0 ? 'py-3' : ''
			])}
		>
			<ul
				className={setClassNames([
					'list-unstyled',
					'd-flex',
					'flex-wrap',
					'align-items-center',
					!useSimplifiedListing ? 'justify-content-center' : '',
					calloutItems.length === 0 ? 'hidden' : ''
				])}
				data-location={
					isTabbedLayout
						? vehicleBadgeLocation
						: `${vehicleMediaLocation} ${vehicleBadgeLocation}`
				}
			>
				{calloutItems}
			</ul>
		</div>
	);
};

Callout.propTypes = {
	callouts: PropTypes.arrayOf(PropTypes.shape({})),
	vehicle: PropTypes.shape({})
};

Callout.defaultProps = {
	callouts: [],
	vehicle: {}
};

export default Callout;
