import React from 'react';
import ReactDOM from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useLabels, useRequestData, usePrefs } from 'wsm-common-data';
import { trackEvent } from 'ddc-track-event';
import { isBrowser } from 'ws-scripts/modules/environment';
import { removeAllVehicles } from '../actions/compare';
import { getCompareLink, getShouldShowCompare } from '../utilities/compare';

const ComparePortal = () => {
	const { enableCompare } = usePrefs();
	const { widgetName, windowId } = useRequestData();
	const shouldShowCompare = getShouldShowCompare(enableCompare);
	const dispatch = useDispatch();
	const { compareVehicles } = useSelector((state) => state.compareVehicles);
	const labels = useLabels();
	const compareLink = getCompareLink(compareVehicles);

	const handleClear = () => {
		dispatch(removeAllVehicles());
		trackEvent(widgetName, windowId, {
			element: 'Clear compare selections button',
			result: 'Removed all vehicles from comparison'
		});
	};
	const compareButtons = (
		<React.Fragment>
			<a
				className="btn btn-primary mr-4"
				onClick={() => {
					trackEvent(widgetName, windowId, {
						element: 'Compare Button',
						result: 'Navigated to comparison page'
					});
				}}
				href={compareLink}
			>
				{labels.get('COMPARE_SELECTED')}
			</a>
			<button
				className="btn btn-sm btn-no-decoration text-link"
				onClick={handleClear}
				type="button"
			>
				<span>{labels.get('DESELECT_ALL')}</span>
			</button>
		</React.Fragment>
	);

	if (isBrowser) {
		const portalElement = document.getElementById(
			'compare-content-buttons-portal'
		);
		if (shouldShowCompare && portalElement && compareVehicles.length > 1) {
			return ReactDOM.createPortal(compareButtons, portalElement);
		}
	}
	return null;
};

export default ComparePortal;
