import React from 'react';
import { useSelector } from 'react-redux';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { useRequestData } from 'wsm-common-data';
import { setClassNames } from 'ddc-classnames-js';
import { trackEvent } from 'ddc-track-event';

import ConditionalWrapper from '../ConditionalWrapper';

const badgeName = 'vehicle-card-badge';

const CalloutItem = ({
	badgeClasses = [],
	href = null,
	imageAlt,
	imageSrc,
	imageTitle,
	index,
	target = null,
	tooltip = ''
}) => {
	const { useSimplifiedListing } = useSelector((state) => state.layout);
	const { widgetName, windowId } = useRequestData();

	// image element needs a height/width set for svg logo to render correctly
	const isCarfax = badgeClasses.includes('carfax');
	const carfaxLogoAttr = isCarfax ? '100%' : undefined;
	const imageInitHeight = !isCarfax ? '30px' : undefined;
	const imageStyles = { aspectRatio: 'auto 8 / 3' };

	const img = (
		<img
			src={imageSrc}
			width={carfaxLogoAttr}
			height={imageInitHeight}
			alt={imageAlt}
			title={imageTitle}
			style={imageStyles}
		/>
	);

	const hasTooltip = !!tooltip && typeof tooltip === 'string';
	/* eslint-disable react/no-danger */
	const tooltipMarkup = (
		<Popover id="popover">
			<span dangerouslySetInnerHTML={{ __html: tooltip }} />
		</Popover>
	);
	/* eslint-enable react/no-danger */

	const decodedHref = href !== null ? href.replace(/&amp;/g, '&') : '';

	const markup = href ? (
		<a
			href={decodedHref}
			className={setClassNames([...badgeClasses])}
			target={target}
			onClick={() =>
				trackEvent(widgetName, windowId, {
					element: 'Badge button',
					elementCTA: imageTitle
				})
			}
			rel={href.startsWith('http') ? 'nofollow' : undefined}
		>
			{img}
		</a>
	) : (
		img
	);

	return (
		<li
			key={imageTitle}
			className={setClassNames([
				badgeName,
				...badgeClasses,
				useSimplifiedListing ? 'py-0 mb-3' : 'py-3',
				useSimplifiedListing && index === 0 ? 'pl-0 mr-2' : 'm-2'
			])}
		>
			<ConditionalWrapper
				condition={hasTooltip}
				wrapper={(children) => (
					<OverlayTrigger placement="top" overlay={tooltipMarkup}>
						{children}
					</OverlayTrigger>
				)}
			>
				{markup}
			</ConditionalWrapper>
		</li>
	);
};

export default CalloutItem;

CalloutItem.propTypes = {
	badgeClasses: PropTypes.arrayOf(PropTypes.string),
	href: PropTypes.string,
	imageAlt: PropTypes.string,
	imageSrc: PropTypes.string,
	imageTitle: PropTypes.string,
	index: PropTypes.number,
	target: PropTypes.string,
	tooltip: PropTypes.string
};

CalloutItem.defaultProps = {
	badgeClasses: [],
	href: null,
	target: null,
	tooltip: ''
};
