import {
	ADD_COMPARE_VEHICLE,
	REMOVE_COMPARE_VEHICLE,
	REMOVE_ALL_COMPARE_VEHICLES
} from '../actions/types';

export default function compareVehicles(state = [], action) {
	if (!action.error && action.payload) {
		switch (action.type) {
			case ADD_COMPARE_VEHICLE:
				return {
					...state,
					compareVehicles: [...state.compareVehicles, action.payload]
				};
			case REMOVE_COMPARE_VEHICLE:
				return {
					...state,
					compareVehicles: state.compareVehicles.filter(
						(vehicleUUID) => vehicleUUID !== action.payload
					)
				};
			case REMOVE_ALL_COMPARE_VEHICLES:
			default:
				return {
					...state,
					compareVehicles: []
				};
		}
	}

	return state;
}
