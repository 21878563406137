import {
	ADD_COMPARE_VEHICLE,
	REMOVE_COMPARE_VEHICLE,
	REMOVE_ALL_COMPARE_VEHICLES
} from './types';

export const addCompareVehicle = (uuid) => {
	return {
		type: ADD_COMPARE_VEHICLE,
		payload: uuid
	};
};

export const removeCompareVehicle = (uuid) => {
	return {
		type: REMOVE_COMPARE_VEHICLE,
		payload: uuid
	};
};

export const removeAllVehicles = () => {
	return {
		type: REMOVE_ALL_COMPARE_VEHICLES,
		payload: {}
	};
};
